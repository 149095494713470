$(document).ready(function() {

    let throttleTimer;
    const throttleDelay = 150; 

    $(window).on('scroll', function() {
        if (!throttleTimer) {
            throttleTimer = setTimeout(function() {
                const scrollPosition = $(window).scrollTop();

                // Calculate background position for '.testimonials'
                let backgroundPositionY;
                if (scrollPosition <= 235) {
                    backgroundPositionY = -200;
                } else if (scrollPosition >= 2000) {
                    backgroundPositionY = -60;
                } else {
                    const progress = (scrollPosition - 235) / (2000 - 235);
                    backgroundPositionY = -200 + progress * (-60 - -200);
                }
                $('.testimonials').css('background-position', `center ${backgroundPositionY}px`);

                // Calculate background position for '.our-mission'
                let backgroundPositionTwoY;
                if (scrollPosition <= 2113) {
                    backgroundPositionTwoY = -200;
                } else if (scrollPosition >= 4700) {
                    backgroundPositionTwoY = -60;
                } else {
                    const progressTwo = (scrollPosition - 2113) / (4700 - 2113);
                    backgroundPositionTwoY = -200 + progressTwo * (-60 - -200);
                }
                $('.our-mission').css('background-position', `center ${backgroundPositionTwoY}px`);

                if(scrollPosition >= 250){
                    $('.mouse-image').hide();
                } else {
                    $('.mouse-image').show();
                }

                throttleTimer = null; 
            }, throttleDelay);
        }
    });

    $('.hamburger').click(function(e){
        e.preventDefault();
        $(this).toggleClass('is-active');
        $(".main-menu__menu").addClass('active');
        $(".main-menu__close").addClass('active');
        $("body").addClass('body-active');
    });

    $('.main-menu__close').click(function(e){
        e.preventDefault();
        $(".main-menu__menu").removeClass('active');
        $(".main-menu__close").removeClass('active');
        $(".hamburger").removeClass('is-active');
        $("body").removeClass('body-active');
    });

    $('.course__form-select').change(function(){
        var courseValue = $(this).val(); // Get the selected value
        if(courseValue !== "Piano" && courseValue !== "Keyboard"){
            $('#radioHide').hide();
            $('input[name="lesson-type"][value="online"]').prop('checked', false);
            $('input[name="lesson-type"][value="face-to-face"]').prop('checked', true);
        } else {
            $('#radioHide').show();
        }
    });
    


    $('.our-courses__btn').hover(
        function() {
            $(this).closest('.our-courses__item').addClass('active');
        },
        function() {
            $(this).closest('.our-courses__item').removeClass('active');
        }
    );
    


    



    $('.home-carousel').owlCarousel({
        autoplayTimeout: 10000,
        loop:true,
        margin:10,
        nav:true,
        dots:false,
        autoplay: true,
        autoPlaySpeed: 5000,
        navText: [
            '<img alt="Previous" width="50" height="70" src="images/prev.svg">',
            '<img alt="Next" width="50" height="70" src="images/next.svg">',
        ],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        },
        animateOut: 'fadeOut'
    });

    $('.owl-testimonial').owlCarousel({
        autoplayTimeout: 10000,
        loop:true,
        margin:10,
        nav:true,
        dots:false,
        autoplay: true,
        autoPlaySpeed: 5000,
        navText: [
            '<img alt="Previous" width="50" height="70" src="images/prev.svg">',
            '<img alt="Next" width="50" height="70" src="images/next.svg">',
        ],
        responsive:{
            0:{
                items:1
            },
            560:{
                items:2
            },
            880:{
                items:3
            },
            1360:{
                items:4
            }
        },
        animateOut: 'fadeOut'
    });

    $('.owl-mission').owlCarousel({
        autoplayTimeout: 10000,
        loop:true,
        margin:10,
        nav:true,
        dots:false,
        autoplay: true,
        autoPlaySpeed: 5000,
        navText: [
            '<img alt="Previous" width="50" height="70" src="images/prev.svg">',
            '<img alt="Next" width="50" height="70" src="images/next.svg">',
        ],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        },
        animateOut: 'fadeOut'
    });

});

/*
document.addEventListener('DOMContentLoaded', function () {
    const banner = document.getElementById('gdpr-banner');
    const acceptButton = document.getElementById('accept-cookies');
    const rejectButton = document.getElementById('reject-cookies');

    // Check if the consent has already been given
    if (document.cookie.includes('gdpr_consent=accepted')) {
        banner.style.display = 'none';
        loadGoogleTagManager();
    } else if (document.cookie.includes('gdpr_consent=rejected')) {
        banner.style.display = 'none';
    }

    // Accept cookies
    acceptButton.addEventListener('click', function () {
        document.cookie = 'gdpr_consent=accepted; path=/; max-age=' + 60 * 60 * 24 * 365;
        banner.style.display = 'none';
        loadGoogleTagManager();
    });

    // Reject cookies
    rejectButton.addEventListener('click', function () {
        document.cookie = 'gdpr_consent=rejected; path=/; max-age=' + 60 * 60 * 24 * 365;
        banner.style.display = 'none';
    });

    function loadGoogleTagManager() {
        console.log('Loading Google Tag Manager...');

        if (!document.querySelector('script#gtm-script')) { // More reliable check
            const script = document.createElement('script');
            script.id = 'gtm-script';
            script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-MQBNDFF9';
            script.async = true;

            console.log('Appending GTM script to the head...');

            // More robust head targeting
            const head = document.head || document.getElementsByTagName('head')[0];
            
            // Add a slight delay to avoid async issues
            setTimeout(() => {
                head.appendChild(script);
                console.log('GTM script appended to the head.');
            }, 100);

            script.onload = function() {
                console.log('GTM script has been loaded successfully.');
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({ event: 'gtm.js' });
            };

            script.onerror = function() {
                console.error('Failed to load GTM script.');
            };
        }
    }

    // Track page navigation (useful for SPAs or AJAX navigation)
    window.addEventListener('popstate', function() {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'page_view',
            page_path: window.location.pathname
        });
    });
});
*/

